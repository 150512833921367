import React, { useCallback, useEffect, useState } from "react";

import { CheckCircle, ContentCopy, DoNotDisturbOn } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import styles from "./UsersQuizTable.module.css";

import Papa from "papaparse";
import { saveAs } from "file-saver";
import ControlledCheckboxRealEmail from "../../ControlledCheckboxRealEmail";
import { splitName } from "../../../utils/Constants";
import EditUserButtonAlert from "../../EditUserButtonAlert";
import { db } from "../../../utils/firebase";
import AddBusinessButtonAlert from "./AddBusinessButtonAlert";

export default function UsersQuizTable() {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);

  const [users, setUsers] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const [lastVisible, setLastVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const history = useHistory();

  const headers = [
    "Started a Quiz",
    "Edit",
    "First name",
    "Last name",
    "Email",
    "$ Value",
    "POC",
    "State",
    "District",
    "Email bounced",
    "Never bounce",
    "Has Created Team",
    "Has Created Team Project",
    "Teams Students Added",
    "Teams Replit Imports",
    "Teams Codingrooms Imports",
    "Teams Templates Used",
    "Teams Templates",
    "Total Quiz Games started",
    "Total Custom Quiz Games Started",
    "Total Assigned Quiz Games Started",
    "Quizzes Started Names",
    "Quizzes Started Courses",
    "Clicked For More Info",
    "Clicked For More Info ProjectId",
    "Last Seen",
    "Subscription",
  ];

  // Utility function to parse file systems
  const parseFileSystem = (fileSystem) => {
    if (!fileSystem) return [];

    return typeof fileSystem === "string" ? JSON.parse(fileSystem) : fileSystem;
  };

  // Utility function to format timestamps
  const formatTimestamp = (timestamp) => {
    const milliseconds =
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6);
    const date = new Date(milliseconds);

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;

    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;

    return `${formattedDate} ${formattedTime}`;
  };

  // Function to process the file system recursively
  const processFileSystem = async (fileSystem, teamStats, userId) => {
    await Promise.all(
      fileSystem.map(async (file) => {
        const {
          id: fileId,
          name: lessonName,
          fileSystem: nestedFileSystem,
        } = file;

        const courseItemDoc = await db
          .collection("teamsCourseItems")
          .doc("" + fileId)
          .get();

        if (courseItemDoc.exists) {
          const courseItem = courseItemDoc.data();

          let hasInitializedIDE = false;
          let lastSeenTotal = null;

          const getIDEInitializedStatus = async (ideId) => {
            let isInitialized = false;
            const teamsUserProjectsDoc = await db
              .collection("teamsUserProjects")
              .where("userId", "==", userId)
              .where("teamProjectId", "==", ideId)
              .get();

            let lastSeen = null;

            if (teamsUserProjectsDoc.docs.length > 0) {
              const teamUserProject = teamsUserProjectsDoc.docs[0].data();

              isInitialized = true;
              lastSeen = teamUserProject.createdAt;
            }

            return { isInitialized, lastSeen };
          };

          const getLessonContentSubmissionStatus = async (courseItem) => {
            let submitted = false;

            // Check if the user has submitted any component of the lesson content
            const content = JSON.parse(courseItem.content)?.content || [];
            if (!Array.isArray(content)) {
              return {
                submitted: false,
                lastSeen: null,
              };
            }

            for (const item of content) {
              const itemId = item?.attrs?.id || item?.attrs?.projectId;

              if (!itemId) {
                break;
              }
              const teamsQuestionSubmissionsSnapshot = await db
                .collection("teamsQuestionSubmissions")
                .where("userId", "==", userId)
                .where("questionId", "==", itemId)
                .get();

              if (!teamsQuestionSubmissionsSnapshot.empty) {
                return {
                  submitted: true,
                  lastSeen:
                    teamsQuestionSubmissionsSnapshot.docs[0].data().createdAt,
                };
              }

              if (item?.attrs?.projectId) {
                const { isInitialized, lastSeen } =
                  await getIDEInitializedStatus(item?.attrs?.projectId);
                if (isInitialized) {
                  return {
                    submitted: true,
                    lastSeen,
                  };
                }
              }

              if (item?.type === "tabs") {
                for (const tab of item?.attrs?.tabs || []) {
                  return getLessonContentSubmissionStatus({
                    ...courseItem,
                    content: tab?.content?.content || [],
                  });
                }
              }

              if (submitted) break;
            }

            return { submitted };
          };

          if (courseItem.type === "coding-assignment") {
            const { isInitialized, lastSeen: lastSeenIDE } =
              await getIDEInitializedStatus(courseItem.id);

            hasInitializedIDE = isInitialized;
            lastSeenTotal = lastSeenIDE;
          }

          let hasSubmittedContent = false;
          if (courseItem.type === "lesson") {
            const { submitted, lastSeen: lastSeenLesson } =
              await getLessonContentSubmissionStatus(courseItem);

            hasSubmittedContent = submitted;
            lastSeenTotal = lastSeenLesson;
          }

          if (hasSubmittedContent || hasInitializedIDE) {
            const existingStat = teamStats?.find(
              (stat) => stat.lessonName === lessonName
            );

            if (existingStat) {
              existingStat.studentSubmissions += 1;
              if (
                lastSeenTotal &&
                (!existingStat.lastSeen ||
                  new Date(lastSeenTotal) > new Date(existingStat.lastSeen))
              ) {
                existingStat.lastSeen = lastSeenTotal;
              }
            } else {
              teamStats.push({
                lessonName,
                studentSubmissions: 1,
                lastSeen: lastSeenTotal,
              });
            }
          }
        } else {
          // console.log("course item doc doesnt exist", fileId);
        }

        if (nestedFileSystem) {
          await processFileSystem(nestedFileSystem, teamStats, userId);
        }
      })
    );
  };

  // Function to process team stats
  const processTeamStatsData = async (team, templatesStats, teacherId) => {
    const fileSystem = parseFileSystem(team.fileSystem);
    const teamStats = [];

    const teamMembers = Object.keys(team.teamMembers || {}).filter(
      (memberId) => memberId !== teacherId
    );

    // await Promise.all(
    let count = 0;
    for (const userId of teamMembers || []) {
      count += 1;

      await processFileSystem(fileSystem, teamStats, userId);

      console.log(
        "finished processing file system",
        count,
        "/",
        teamMembers.length,
        teamStats
      );
    }

    templatesStats.push({
      name: team.name,
      templateName: team?.forkedFromCourse,
      isJMCourse: !!team?.forkedFromCourse,
      lessonStats: teamStats,
    });

    console.log("templatesStats", team.name);
  };

  // Function to stringify template stats
  const stringifyTemplateStats = (templatesStats) => {
    if (!templatesStats || !templatesStats.length) {
      return "";
    }

    const renderLessonStats = (stats) => {
      return stats
        .map(
          (stat) =>
            `{${stat.lessonName}, ${stat.studentSubmissions || 0}, ${
              stat.lastSeen ? formatTimestamp(stat.lastSeen) : "-"
            }}`
        )
        .filter((x) => x)
        .join(",\n");
    };

    return templatesStats
      .map((stat) => {
        if (stat.lessonStats) {
          // not sure why we're filtering out lastSeen
          // stat.lessonStats = stat.lessonStats.filter((stat) => stat?.lastSeen);
        }
        if (!stat?.lessonStats?.length) return "";

        return `{${stat.name} - (${
          stat.isJMCourse ? `JM_COURSE - ${stat.templateName}` : "CUSTOM"
        }) -> [${renderLessonStats(stat.lessonStats)}]}`;
      })
      .filter((x) => x)
      .join(",\n");
  };

  // Main function to get team data
  const getTeamData = async (userId) => {
    try {
      const storedTeamData = await db
        .collection("dashboardTeamsUsage")
        .doc(userId)
        .get();

      if (storedTeamData.exists) {
        return storedTeamData.data();
      }

      console.log("no stored teams data doc");

      const querySnapshot = await db
        .collection("teams")
        .where("userId", "==", userId)
        .get();

      const teamsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const totalReplitImports = `${
        teamsData.filter((team) => team?.isReplitV2).length
      }/${teamsData.length}`;
      const totalCodingroomImports = `${
        teamsData.filter((team) => team?.isCodingRooms).length
      }/${teamsData.length}`;
      const totalTemplatesUsed = `${
        teamsData.filter((team) => team?.forkedFromCourse).length
      }`;

      const templatesStats = [];

      console.log("total teams", teamsData.length);

      // Sequentially process each team's data
      let teamsCount = 0;
      if (teamsData.length < 1000) {
        for (const team of teamsData) {
          if (team?.teamMembers && Object.keys(team?.teamMembers)?.length > 0) {
            teamsCount += 1;
            await processTeamStatsData(team, templatesStats, userId);
            console.log(
              "teamsCount",
              teamsCount,
              "/",
              teamsData.length,
              JSON.parse(JSON.stringify(templatesStats))
            );
          }
        }
      }

      // if (userId === "rldhOAx2OIdIH4C2NWgwQNx8fMV2") {
      // console.log(
      //   "we here with templatesStats",
      //   JSON.parse(JSON.stringify(templatesStats))
      // );
      // console.log("we here with teams", teamsData);
      // }

      // console.log(
      //   "stringifyTemplateStats",
      //   stringifyTemplateStats(JSON.parse(JSON.stringify(templatesStats)))
      // );

      const returnValue = {
        totalReplitImports,
        totalCodingroomImports,
        totalTemplatesUsed,
        templatesStats:
          stringifyTemplateStats(JSON.parse(JSON.stringify(templatesStats))) ||
          "-",
      };

      await db.collection("dashboardTeamsUsage").doc(userId).set(returnValue);

      console.log("Saved successfully!", userId);

      return returnValue;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async (search = "") => {
    setLoading(true);
    let query = db
      .collection("users")
      .where("isTeacher", "==", true)
      .orderBy("createdAt", "desc")
      .limit(50);

    // Check if there's a search term for the email
    if (search.trim() !== "") {
      // Adjust the query to filter by exact email match
      query = db
        .collection("users")
        .where("isTeacher", "==", true)
        .where("email", "==", search.trim());
      console.log("email ==", search.trim());
    } else if (lastVisible) {
      // Apply pagination for non-search queries
      query = query.startAfter(lastVisible);
    }

    query
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.docs.length < 50) {
          setHasMore(false); // If fewer documents than the limit are returned, no more data is available
        } else {
          setHasMore(true);
        }
        const lastVisibleDoc =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const itemsWithTeamsDataPromises = [];
        items.forEach((userData) => {
          itemsWithTeamsDataPromises.push(
            getTeamData(userData.id).then((teamData) => {
              return { ...userData, ...teamData };
            })
          );
        });

        const itemsWithTeamsData = await Promise.all(
          itemsWithTeamsDataPromises
        );
        if (search.trim() !== "") {
          // For searches, replace the current users list with the new results
          setUsers(itemsWithTeamsData);
        } else {
          // For pagination, append the new results
          setUsers((prev) => [...prev, ...itemsWithTeamsData]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const main = async () => {
      setUsers([]);
      fetchData(searchQuery || "");
    };
    main();
  }, [searchQuery]);

  const getUsersDataArray = useCallback(
    (items) => {
      return items.map((row) => {
        const emailDomain = row.email.split("@")[1];

        const matchingDomain = businessData.find(
          (mapping) => mapping.id === emailDomain
        );

        const onUpdateStateAndDistrict = ({ state, district }) => {
          setBusinessData((prev) => {
            return prev.map((mapping) => {
              if (mapping.id === emailDomain) {
                return {
                  ...mapping,
                  state,
                  district,
                };
              }
              return mapping;
            });
          });
        };

        return [
          {
            id: row.id,
            onClick: () => history.push("/quizzes/user/" + row.id),
            text: row?.name ? splitName(row?.name)?.firstName : "NO NAME",
            ...(matchingDomain?.state || matchingDomain?.district
              ? {
                  state: matchingDomain?.state || "",
                  district: matchingDomain?.district || "",
                  onUpdateStateAndDistrict: onUpdateStateAndDistrict,
                }
              : {}),
          },
          {
            text: row?.name ? splitName(row?.name)?.lastName : "NO NAME",
          },
          { isCopyable: true, text: row.email },
          { isCopyable: false, text: row?.subscriptionValue || "-" },
          { isCopyable: false, text: row?.poc || "-" },
          ...(matchingDomain?.state && matchingDomain?.district
            ? [
                {
                  text: matchingDomain?.state,
                  isState: false,
                  userId: row.id,
                },
                { text: matchingDomain?.district },
              ]
            : [
                {
                  text: matchingDomain?.state || null,
                  isState: !matchingDomain?.district && !matchingDomain?.state,
                  userId: row?.id,
                },
                {
                  text: matchingDomain?.district || null,
                  userId: row?.id,
                },
              ]),
          {
            text:
              row.emailIsReal != null
                ? !row.emailIsReal
                : row.neverBounceResponse == "invalid",
            onChange: async (newVal) => {
              const emailIsReal = !newVal;
              console.log("row.id", emailIsReal);
              await db.collection("users").doc(row.id).update({
                emailIsReal: emailIsReal,
              });

              console.log("emailIsReal", emailIsReal);

              setUsers(
                users.map((userPrevState) => {
                  if (userPrevState.id === row.id) {
                    console.log("user chosed", userPrevState);
                    let newObj = {
                      ...userPrevState,
                      emailIsReal: emailIsReal,
                    };
                    console.log("newObj", newObj);
                    return newObj;
                  }
                  return userPrevState;
                })
              );
            },
          },
          {
            text:
              row.emailIsReal != null && !row.emailIsReal
                ? "invalid"
                : row.neverBounceResponse,
          },
          // row.customCreatedQuizzes.map(({ name }) => name + ", "),
          { text: row.ownsATeam ? "Yes" : "No" },
          { text: row.hasCreatedATeamProject ? "Yes" : "No" },
          { text: row.totalTeamMembersAdded || 0 },
          { text: row.totalReplitImports },
          { text: row.totalCodingroomImports },
          { text: row.totalTemplatesUsed },
          { text: row.templatesStats || "-" },
          { text: row.totalQuizGamesStarted },
          { text: row.totalCustomQuizGamesStarted },
          { text: row.totalAssignedQuizGamesStarted },
          // { text: row.totalQuizzesStarted },
          // {
          //   text:
          //     row.quizTypesList &&
          //     row.quizTypesList.map((quizType) => quizType + ", "),
          // },
          // {
          //   text:
          //     row.createdQuizzesList &&
          //     row.createdQuizzesList.map((quizName) => quizName + ", "),
          // },
          {
            text:
              row.quizzesStartedNames &&
              JSON.stringify(row.quizzesStartedNames),
          },
          {
            text:
              row.quizzesStartedCourses &&
              JSON.stringify(row.quizzesStartedCourses),
          },
          // {
          //   text:
          //     row.startedQuizzesCourseId &&
          //     row.startedQuizzesCourseId.map(
          //       (courseInfoForQuiz) =>
          //         (courseInfoForQuiz.userId == row.id
          //           ? "CREATED-QUIZ"
          //           : courseInfoForQuiz.courseId) + ", "
          //     ),
          // },
          {
            text:
              row.viewProductOrStartTutorialsClickedTime ||
              row.learnMoreAboutProductClickedTime
                ? "Yes"
                : "No",
          },
          {
            text: row.viewProductOrStartTutorialsProjectId
              ? row.viewProductOrStartTutorialsProjectId
              : row.learnMoreAboutProductProjectId
              ? row.learnMoreAboutProductProjectId
              : "",
          },
          {
            text:
              row.createdAt &&
              row.createdAt?.toDate() &&
              row.createdAt?.toDate()?.toLocaleString()
                ? row.createdAt?.toDate()?.toLocaleString()
                : "",
          },
          {
            text: row.permanentSubscription
              ? row.permanentSubscription
              : row.subscriptionStatus
              ? row.subscriptionStatus
              : row.stripeId
              ? "Clicked trial"
              : "Not subscribed",
            selector: {
              options: [
                {
                  value: row.subscriptionStatus
                    ? row.subscriptionStatus
                    : row.stripeId
                    ? "Clicked trial"
                    : "Not subscribed",
                  text: row.subscriptionStatus
                    ? row.subscriptionStatus
                    : row.stripeId
                    ? "Clicked trial"
                    : "Not subscribed",
                },
                { value: "Starter", text: "Starter" },
                { value: "Silver", text: "Silver" },
                { value: "Gold", text: "Gold" },
                { value: "Diamond", text: "Diamond" },
              ],
              onChange: async (evt) => {
                const newValue = evt.target.value;

                let finalPermValue = null;

                if (
                  newValue == "Gold" ||
                  newValue == "Silver" ||
                  newValue == "Starter" ||
                  newValue == "Diamond"
                ) {
                  finalPermValue = newValue;
                }

                await db.collection("users").doc(row.id).update({
                  permanentSubscription: finalPermValue,
                });

                setUsers((prevState) => {
                  return prevState.map((user) => {
                    if (user.id === row.id) {
                      // Check if this is the object we want to update
                      return {
                        ...user, // Copy all existing user properties
                        permanentSubscription: finalPermValue, // Update the specific field
                      };
                    } else {
                      return user; // Return the unchanged object
                    }
                  });
                });
              },
            },
          },
        ];
      });
    },
    [businessData, history, users]
  );

  const generateCSVData = async () => {
    try {
      console.log("querying the users");
      let query = db
        .collection("users")
        .where("isTeacher", "==", true)
        .orderBy("createdAt", "desc")
        .limit(500); // Start with a limit of 500

      console.log("done querying the users");

      const dashboardTeamsUsageSnapshot = await db
        .collection("dashboardTeamsUsage")
        .get();

      const dashboardTeamsUsageStore = dashboardTeamsUsageSnapshot.docs.map(
        (doc) => ({
          id: doc.id,
          ...doc.data(),
        })
      );

      let allItems = [];
      let lastDoc = null;

      while (true) {
        console.log("processing next 500 documents");
        const querySnapshot = await query.get();
        console.log("done processing the 500", querySnapshot.empty);

        // Check if there are documents
        if (querySnapshot.empty) break;

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        allItems = [...allItems, ...items];

        // Get the last document to use as the starting point for the next batch
        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Update the query to fetch the next batch
        query = db
          .collection("users")
          .where("isTeacher", "==", true)
          .orderBy("createdAt", "desc")
          .startAfter(lastDoc)
          .limit(500);
      }

      const batchSize = 1; // Limit concurrent requests to 50 at a time
      const itemsWithTeamsData = [];

      for (let i = 0; i < allItems.length; i += batchSize) {
        console.log("processing batch", i, "/", allItems.length);
        const batch = allItems.slice(i, i + batchSize);
        const batchPromises = batch.map(async (userData) => {
          const storedTeamData = dashboardTeamsUsageStore.find(
            (teamData) => teamData.id === userData.id
          );
          // console.log("userData.id", userData.id);
          const teamData = storedTeamData
            ? storedTeamData
            : await getTeamData(userData.id);

          // console.log("userData.email", userData.email, teamData);

          return { ...userData, ...teamData };
        });
        // console.log("processing batch", i, allItems.length);
        const batchResults = await Promise.all(batchPromises); // Process one batch at a time
        itemsWithTeamsData.push(...batchResults);
      }

      console.log("about to process csvData");

      const csvData = [
        headers.slice(2),
        ...getUsersDataArray(itemsWithTeamsData).map((innerArray) =>
          innerArray.map((obj) => obj.text)
        ),
      ];

      return csvData;
    } catch (error) {
      setIsDownloadingCSV(false);
      console.error("Error generating CSV data:", error);
    }
  };

  const downloadCSV = async () => {
    setIsDownloadingCSV(true);
    const csvData = await generateCSVData();
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "table-data.csv");
    setIsDownloadingCSV(false);
  };

  const copyToClipboard = async (email) => {
    try {
      await navigator.clipboard.writeText(email);
      setShowCopySuccess(true);
    } catch (err) {
      setShowCopySuccess(false);
    }
  };

  const fetchBusinessName = async () => {
    const domainToSchoolDistrictAndStateMappingSnapshot = await db
      .collection("domainToSchoolDistrictAndStateMapping")
      .get();

    const domainToSchoolDistrictAndStateMapping =
      domainToSchoolDistrictAndStateMappingSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

    setBusinessData(domainToSchoolDistrictAndStateMapping);
  };

  useEffect(() => {
    fetchBusinessName();
  }, []);

  return (
    <Paper sx={{ width: "100%", position: "relative" }}>
      <button
        style={{
          position: "absolute",
          top: "-54px",
          right: "180px",
          backgroundColor: showSubscriptions ? "blue" : "lightgrey",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
          border: "4px solid rgba(0,0,0,.2)",
        }}
        onClick={() => setShowSubscriptions((prevState) => !prevState)}
      >
        Subscribers
      </button>
      <button
        style={{
          position: "absolute",
          top: "-54px",
          right: "30px",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
          border: "4px solid rgba(0,0,0,.2)",
        }}
        onClick={() => downloadCSV()}
      >
        {isDownloadingCSV ? "Downloading..." : "Download CSV"}
      </button>

      <input
        placeholder="Search by Email"
        style={{
          width: "100%",
          border: "1px solid lightgrey",
          borderRadius: "10px",
          fontSize: "24px",
          padding: "10px",
        }}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getUsersDataArray(users).map((user) => {
              return (
                <TableRow
                  // onClick={() => history.push("/quizzes/user/" + user.id)}
                  className={styles.tableRow}
                  key={user.id}
                >
                  <TableCell>
                    {user.hasStartedQuiz ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      <DoNotDisturbOn style={{ color: "grey" }} />
                    )}
                  </TableCell>
                  <TableCell>
                    <EditUserButtonAlert
                      userRef={user[0]?.id}
                      firstName={user[0]?.text}
                      lastName={user[1]?.text}
                      userName={user.adminName ? user.adminName : user.name}
                      userEmail={user[2]?.text}
                      state={user[0]?.state}
                      district={user[0]?.district}
                      onUpdateStateAndDistrict={
                        user[0]?.onUpdateStateAndDistrict
                      }
                      subscriptionValue={
                        user[3]?.text === "-" || !user[3]?.text
                          ? ""
                          : user[3]?.text
                      }
                      poc={
                        user[4]?.text === "-" || !user[4]?.text
                          ? ""
                          : user[4]?.text
                      }
                      onFinish={fetchData}
                      onUpdate={(newName, newEmail) =>
                        setUsers((prevState) => {
                          return prevState.map((userPrevState) => {
                            if (userPrevState.id === user.id) {
                              return {
                                ...userPrevState,
                                adminName: newName,
                                adminEmail: newEmail,
                              };
                            }
                            return userPrevState;
                          });
                        })
                      }
                    />
                  </TableCell>
                  {user.map(
                    (
                      {
                        text,
                        onClick,
                        onChange,
                        isCopyable,
                        selector,
                        isState,
                        userId,
                      },
                      index
                    ) => (
                      <TableCell
                        onClick={
                          onClick
                            ? () => history.push("/quizzes/user/" + user.id)
                            : () => {}
                        }
                      >
                        {isState ? (
                          <AddBusinessButtonAlert
                            users={users}
                            userId={userId}
                            onFinish={() => {
                              fetchBusinessName();
                              fetchData();
                            }}
                          />
                        ) : null}
                        {headers[index + 2] == "Email bounced" ? (
                          <ControlledCheckboxRealEmail
                            value={text}
                            onChange={onChange}
                          />
                        ) : (
                          <>
                            {isCopyable && (
                              <IconButton
                                sx={{ marginRight: "10px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  copyToClipboard(text);
                                }}
                              >
                                <ContentCopy sx={{ fontSize: "20px" }} />
                              </IconButton>
                            )}

                            {selector ? (
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Subscription
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={text}
                                  label="Age"
                                  onChange={selector.onChange}
                                >
                                  {selector.options.map(({ text, value }) => (
                                    <MenuItem value={value}>{text}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <div
                                style={
                                  onClick
                                    ? {
                                        color: "blue",
                                        textDecoration: "unerline",
                                      }
                                    : {}
                                }
                              >
                                {text}
                              </div>
                            )}
                          </>
                        )}
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          {hasMore && (
            <button
              onClick={() => fetchData(searchQuery || "")}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          )}
        </Table>
      </TableContainer>
      <Snackbar
        open={showCopySuccess}
        onClose={() => setShowCopySuccess(false)}
        autoHideDuration={2000}
        message="Copied email to clipboard"
      />
    </Paper>
  );
}
