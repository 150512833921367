import React, { useEffect, useState } from "react";

import { ContentCopy } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
} from "@mui/material";

import styles from "./ConsumersUsagePage.module.css";

import { db } from "../../utils/firebase";
import firebase from "../../utils/firebase";
import type { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore";
import type { TConsumerUsage } from "./interface";

export default function ConsumersUsagePage() {
  const [search, setSearch] = useState("");
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const [consumerUsage, setConsumerUsage] = useState<TConsumerUsage[]>([]);

  const [lastVisible, setLastVisible] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available

  const headers = ["ID", "Name", "Email", "Usage Status"];

  const getConsumerTeams = async (consumerId: string) => {
    const teamsDocs = await db
      .collection("teams")
      .orderBy(`teamMembers.${consumerId}`, "desc")
      .get();

    const teams = teamsDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return teams;
  };

  const getConsumerExerciseAttempts = async (consumerId: string) => {
    const teamsProgressDocs = await db
      .collection("teamsProgress")
      .where("userId", "==", consumerId)
      .get();

    let totalExercisesAttempted = 0;
    if (teamsProgressDocs.docs.length > 0) {
      teamsProgressDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("data") &&
          typeof doc.data().data === "object"
        ) {
          totalExercisesAttempted +=
            Object.keys(doc.data().data)?.length || totalExercisesAttempted;
        }
      });
    }

    return totalExercisesAttempted;
  };

  const getConsumerTestResults = async (consumerId: string) => {
    const teamsTestResultsDocs = await db
      .collection("teamsTestResults")
      .where("userId", "==", consumerId)
      .get();

    let totalTestResults = 0;
    if (teamsTestResultsDocs.docs.length > 0) {
      teamsTestResultsDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("result") &&
          typeof doc.data().result === "object"
        ) {
          Object.keys(doc.data().result)?.forEach((key) => {
            const testResult = doc.data().result[key];
            if (testResult.passed) {
              totalTestResults++;
            } else if (testResult.failed) {
              totalTestResults++;
            }
          });
        }
      });
    }

    return totalTestResults;
  };

  const getConsumerProjects = async (consumerId: string) => {
    const teamsUserProjectsDocs = await db
      .collection("teamsUserProjects")
      .where("userId", "==", consumerId)
      .get();

    let totalProjectsAttempted = 0;
    if (teamsUserProjectsDocs.docs.length > 0) {
      teamsUserProjectsDocs.docs.forEach((doc) => {
        if (doc.exists) {
          totalProjectsAttempted++;
        }
      });
    }
    return totalProjectsAttempted;
  };

  const getConsumerSubmissions = async (consumerId: string) => {
    let totalSubmissions = 0;
    const studentSubmissionsDocs = await db
      .collection("studentSubmissions")
      .where("userId", "==", consumerId)
      .get();

    if (studentSubmissionsDocs.docs.length > 0) {
      studentSubmissionsDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("submissions") &&
          typeof doc.data().submissions === "object"
        ) {
          totalSubmissions +=
            Object.keys(doc.data().submissions)?.length || totalSubmissions;
        }
      });
    }

    return totalSubmissions;
  };

  const BATCH_SIZE = 50; // Adjust batch size based on your backend's capacity

  const processInBatches = async (members: string[]) => {
    let totalExercisesAttempted = 0;
    let totalTestResults = 0;
    let totalProjectsCreated = 0;
    let totalSubmissions = 0;

    // Helper function to process a single batch
    const processBatch = async (batch: string[]) => {
      await Promise.all(
        batch.map(async (memberId) => {
          const exercisesAttempted = await getConsumerExerciseAttempts(
            memberId
          );
          const testResults = await getConsumerTestResults(memberId);
          const projectsCreated = await getConsumerProjects(memberId);
          const totalLessonSubmissions = await getConsumerSubmissions(memberId);

          totalExercisesAttempted += exercisesAttempted || 0;
          totalTestResults += testResults || 0;
          totalProjectsCreated += projectsCreated || 0;
          totalSubmissions += totalLessonSubmissions || 0;
        })
      );
    };

    // Split members into chunks and process sequentially
    for (let i = 0; i < members.length; i += BATCH_SIZE) {
      const batch = members.slice(i, i + BATCH_SIZE);
      await processBatch(batch);
    }

    return {
      totalExercisesAttempted,
      totalTestResults,
      totalProjectsCreated,
      totalSubmissions,
    };
  };

  const getConsumerWithUsageStatus = async (consumer: any) => {
    const teams = await getConsumerTeams(consumer.id);

    const teamMembers = new Set();
    teams.forEach((team) => {
      if (team.teamMembers && Object.keys(team.teamMembers)?.length > 0) {
        Object.keys(team.teamMembers).forEach((memberId) => {
          teamMembers.add(memberId);
        });
      }
    });

    let totalTeamsCreated = teams?.length || 0;
    const teamMemberIdsArray = Array.from(teamMembers);
    const {
      totalExercisesAttempted,
      totalTestResults,
      totalProjectsCreated,
      totalSubmissions,
    } = await processInBatches(teamMemberIdsArray as string[]);

    // Define weighted contributions of each metric
    const weights = {
      totalTeamsCreated: 0.2,
      totalExercisesAttempted: 0.25,
      totalTestResults: 0.15,
      totalProjectsCreated: 0.3,
      totalSubmissions: 0.1,
    };

    // Normalize the metrics (scale them to be comparable)
    const normalized = {
      totalTeamsCreated: Math.min(totalTeamsCreated / 20, 1),
      totalExercisesAttempted: Math.min(totalExercisesAttempted / 500, 1),
      totalTestResults: Math.min(totalTestResults / 1000, 1),
      totalProjectsCreated: Math.min(totalProjectsCreated / 1000, 1),
      totalSubmissions: Math.min(totalSubmissions / 500, 1),
    };

    // Calculate weighted score
    const score =
      normalized.totalTeamsCreated * weights.totalTeamsCreated +
      normalized.totalExercisesAttempted * weights.totalExercisesAttempted +
      normalized.totalTestResults * weights.totalTestResults +
      normalized.totalProjectsCreated * weights.totalProjectsCreated +
      normalized.totalSubmissions * weights.totalSubmissions;

    // Determine status based on score thresholds
    if (score > 0.75) {
      return {
        name: consumer?.name || "-",
        email: consumer?.email || "-",
        usage: "HEAVY_USAGE",
      };
    } else if (score > 0.5) {
      return {
        name: consumer?.name || "-",
        email: consumer?.email || "-",
        usage: "MODERATE_USAGE",
      };
    } else if (score > 0.25 && score <= 0.5) {
      return {
        name: consumer?.name || "-",
        email: consumer?.email || "-",
        usage: "LOW_USAGE",
      };
    } else {
      return {
        name: consumer?.name || "-",
        email: consumer?.email || "-",
        usage: "NO_USAGE",
      };
    }
  };

  const fetchData = async (search = "") => {
    let query = db
      .collection("users")
      .where("isTeacher", "==", true)
      .orderBy("createdAt", "desc")
      .limit(16);

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    if (search && search.trim() !== "") {
      console.log("Searching: " + search.trim());
      query = db
        .collection("users")
        .where("isTeacher", "==", true)
        .where("email", "==", search.trim());
    }

    query
      .get()
      .then(async (querySnapshot) => {
        setLoading(true);
        if (querySnapshot.docs.length < 16) {
          setHasMore(false); // If fewer documents than the limit are returned, no more data is available
        } else {
          setHasMore(true);
        }
        const lastVisibleDoc =
          querySnapshot.docs[querySnapshot.docs.length - 1];

        setLastVisible(lastVisibleDoc);

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const usageStatusPromises: Promise<TConsumerUsage>[] = [];
        items.forEach((item) => {
          usageStatusPromises.push(getConsumerWithUsageStatus(item));
        });

        return Promise.all(usageStatusPromises);
      })
      .then((data) => {
        // For pagination, append the new results
        setConsumerUsage((prev) => [...prev, ...data]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const main = async (search: string) => {
      setConsumerUsage([]);
      fetchData(search);
    };
    main(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getUserSubscriptionsDataArray = (items: TConsumerUsage[]) => {
    return items.map((row, index) => [
      { text: index + 1 },
      {
        text: row?.name || "-",
      },
      {
        text: row?.email || "-",
        isCopyable: true,
      },
      {
        text: row?.usage || "-",
      },
    ]);
  };

  const copyToClipboard = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      setShowCopySuccess(true);
    } catch (err) {
      setShowCopySuccess(false);
    }
  };

  return (
    <div
      style={{
        padding: "0px 40px",
        marginTop: "42px",
        display: "flex",
        flex: 1,
        width: "90%",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <Paper sx={{ width: "100%", position: "relative", marginBottom: "42px" }}>
        <input
          placeholder="Search by Email"
          style={{
            width: "98%",
            border: "1px solid lightgrey",
            borderRadius: "10px",
            fontSize: "24px",
            padding: "10px",
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={styles.tableRow}>
                {headers.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getUserSubscriptionsDataArray(consumerUsage).map(
                (issue, parentIndex) => {
                  return (
                    <TableRow key={parentIndex}>
                      {issue.map(({ text, isCopyable }, index) => (
                        <TableCell>
                          {(() => {
                            switch (text) {
                              case "USES_PRODUCT_REGULARLY":
                                return "Uses product regularly";
                              case "PRODUCT_USAGE_INCREASED_HEAVILY":
                                return "Product usage increased heavily";
                              case "PRODUCT_USAGE_DECREASED_HEAVILY":
                                return "Product usage decreased heavily";
                              default:
                                return text;
                            }
                          })()}
                          {isCopyable && (
                            <IconButton
                              sx={{ marginRight: "10px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                copyToClipboard(text);
                              }}
                            >
                              <ContentCopy sx={{ fontSize: "20px" }} />
                            </IconButton>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
            {(loading || hasMore) && (
              <button onClick={() => fetchData(search)} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            )}
          </Table>
        </TableContainer>
        <Snackbar
          open={showCopySuccess}
          onClose={() => setShowCopySuccess(false)}
          autoHideDuration={2000}
          message="Copied email to clipboard"
        />
      </Paper>
    </div>
  );
}
